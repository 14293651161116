export const KeyCodes = {
  enter: 13,
  escape: 27,
  left: 37,
  right: 39,
  up: 38,
  down: 40,
  shift: 16,
  alt: 18,
  ctr: 17,
  backspace: 8,
};
